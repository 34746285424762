<script setup>

import { useRouter } from 'vue-router'
import store from '../../../state/store';
// eslint-disable-next-line no-unused-vars
import myMSAL from '../../../msal/myMSAL'
// eslint-disable-next-line no-unused-vars
import { signOut2,InitMsal3 } from '../../../msal/msalInterface'
let router = useRouter()


async function logout() {

    sessionStorage.clear()
    localStorage.clear()
    store.commit('isActive', false)
    store.commit('clearUserData')
    try {
        await InitMsal3()
         signOut2()

    } catch (error) {
        console.log(error)
    }
}


logout()

router.push("/login")


</script>
